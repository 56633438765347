<template>
  <div>
    <h1>Kelly's DB</h1>
    <form @submit.prevent="getGramophoneData">
      <label
        >Artist
        <input ref="artist" type="text" v-model.trim="artist" />
      </label>
      <label
        >Artist Alt
        <input ref="artistAlt" type="text" v-model.trim="artistAlt" />
      </label>
      <label
        >Title
        <input ref="title" type="text" v-model.trim="title" />
      </label>
      <label
        >Title Alt
        <input ref="titleAlt" type="text" v-model.trim="titleAlt" />
      </label>
      <label
        >Prefix
        <input ref="prefix" type="text" v-model.trim="prefix" />
      </label>
      <label
        >Suffix
        <input ref="suffix" type="text" v-model.trim="suffix" />
      </label>
      <label
        >Matrix Number
        <input ref="matrixNumber" type="text" v-model.trim="matrixNumber" />
      </label>
      <label
        >Issue Number
        <input ref="issueNumber" type="text" v-model.trim="issueNumber" />
      </label>
      <label
        >Recording Date
        <input ref="recordingDate" type="text" v-model.trim="recordingDate" />
      </label>
      <label
        >Location
        <input ref="location" type="text" v-model.trim="location" />
      </label>
      <label
        >Filename
        <input ref="filename" type="text" v-model.trim="filename" />
      </label>
      <label
        >Other
        <input ref="other" type="text" v-model.trim="other" />
      </label>
      <label
        >Notes
        <input ref="notes" type="text" v-model.trim="notes" />
      </label>

      <input type="submit" value="Search" />
      <button @click.prevent="clearAllSearchTerms">Clear all</button>
    </form>
    <section tabindex="0" ref="status" role="alert" aria-atomic="true">
      {{ status }}
    </section>
    <button
      id="download"
      v-if="showDownloadButton"
      @click.prevent="downloadTextFile()"
    >
      Download Kellys.txt
    </button>
    <section>
      <table id="results-table">
        <thead>
          <tr>
            <th>Matrix</th>
            <th>Issue numbers</th>
            <th>Performer(s)</th>
            <th>Title</th>
            <th>Recording date</th>
            <th>Location</th>
            <th>Size</th>
            <th>Other</th>
            <th>Kelly file</th>
            <th>Session ID</th>
            <th>Session group ID</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in result.items" :key="record.id">
            <td>
              {{ record.prefix }}-{{ record.matrixNumber
              }}{{ record.takeNumber }}, {{ record.recordingDate | dateFormat
              }}{{ record.suffix ? " " + record.suffix : "" }}
            </td>
            <td>{{ record.issuedNumbersCombined }}</td>
            <td>{{ record.artistsCombined }}</td>
            <td>{{ record.titlesCombined }}</td>
            <td>{{ record.recordingDate | dateFormat }}</td>
            <td>
              {{
                `${record.location.venue ? record.location.venue + ", " : ""}${
                  record.location.city ? record.location.city + ", " : ""
                }${record.location.country}`
              }}
            </td>
            <td>{{ record.size }}</td>
            <td>{{ record.other }}</td>
            <td>{{ record.fileId }}</td>
            <td>{{ record.sessionId }}</td>
            <td>{{ record.sessionGroupId }}</td>
            <td>{{ record.source }}</td>
          </tr>
        </tbody>
      </table>
    </section>
    <section>
      <table id="results-table-hidden" style="display: none;">
        <thead>
          <tr>
            <th>Matrix</th>
            <th>Issue numbers</th>
            <th>Performer(s)</th>
            <th>Title</th>
            <th>Recording date</th>
            <th>Location</th>
            <th>Size</th>
            <th>Other</th>
            <th>Kelly file</th>
            <th>Session ID</th>
            <th>Session group ID</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="record in recordsWithIssueNumbers"
            :key="`${record.id}_filtered`"
          >
            <td>
              {{ record.prefix }}-{{ record.matrixNumber
              }}{{ record.takeNumber }}, {{ record.recordingDate | dateFormat
              }}{{ record.suffix ? " " + record.suffix : "" }}
            </td>
            <td>{{ record.issuedNumbersCombined }}</td>
            <td>{{ record.artistsCombined }}</td>
            <td>{{ record.titlesCombined }}</td>
            <td>{{ record.recordingDate | dateFormat }}</td>
            <td>
              {{
                `${record.location.venue ? record.location.venue + ", " : ""}${
                  record.location.city ? record.location.city + ", " : ""
                }${record.location.country}`
              }}
            </td>
            <td>{{ record.size }}</td>
            <td>{{ record.other }}</td>
            <td>{{ record.fileId }}</td>
            <td>{{ record.sessionId }}</td>
            <td>{{ record.sessionGroupId }}</td>
            <td>{{ record.source }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import axios from "axios";

function htmlTableToCSV(table) {
  let csv = [];
  let rows = table.querySelectorAll("tr");
  for (let i = 0; i < rows.length; i++) {
    let row = [];
    let cols = rows[i].querySelectorAll("td, th");
    for (let j = 0; j < cols.length; j++) {
      row.push(cols[j].innerText);
    }
    csv.push(row.join("\t"));
  }
  return csv.join("\r\n");
}

export default {
  data() {
    return {
      artist: "",
      artistAlt: "",
      title: "",
      titleAlt: "",
      prefix: "",
      suffix: "",
      matrixNumber: "",
      issueNumber: "",
      recordingDate: "",
      location: "",
      filename: "",
      other: "",
      notes: "",
      result: "",
      status: "",
    };
  },
  mounted() {
    this.$refs.artist.focus();
  },
  computed: {
    showDownloadButton() {
      return this.result && this.result.items.length > 0;
    },
    recordsWithIssueNumbers() {
      if (this.result && this.result.items.length > 0) {
        return this.result.items.filter(
          (record) => record.issuedNumbersCombined !== ""
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    clearAllSearchTerms() {
      this.artist = "";
      this.artistAlt = "";
      this.title = "";
      this.titleAlt = "";
      this.prefix = "";
      this.suffix = "";
      this.matrixNumber = "";
      this.issueNumber = "";
      this.recordingDate = "";
      this.location = "";
      this.filename = "";
      this.other = "";
      this.notes = "";
      this.status = "";
      this.result = "";
    },
    async getGramophoneData() {
      const url =
        // "http://localhost:5000/kellysdb" +
        "https://plxbahnb4h.execute-api.ap-southeast-2.amazonaws.com/kellysdb" +
        `?artist=${this.artist}` +
        `&artistAlt=${this.artistAlt}` +
        `&title=${this.title}` +
        `&titleAlt=${this.titleAlt}` +
        `&prefix=${this.prefix}` +
        `&suffix=${this.suffix}` +
        `&matrixNumber=${this.matrixNumber}` +
        `&issueNumber=${this.issueNumber}` +
        `&recordingDate=${this.recordingDate}` +
        `&location=${this.location}` +
        `&filename=${this.filename}` +
        `&other=${this.other}` +
        `&notes=${this.notes}`;

      axios.defaults.crossDomain = true;

      this.status = "Loading";
      try {
        let response = await axios({
          method: "get",
          url,
        });
        this.status = `Search complete, found ${response.data.total} results for ${response.data.criteriaDescription}`;
        this.result = response.data;
      } catch (e) {
        this.status = "Something went wrong.";
        this.result = "";
      }

      this.$nextTick(() => {
        this.$refs.status.focus();
      });
    },
    downloadTextFile() {
      let table = document.getElementById("results-table-hidden");
      let csv = htmlTableToCSV(table);

      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
      );
      element.setAttribute("download", "Kellys.txt");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<style>
label {
  display: block;
}

table > thead > tr > th {
  text-align: left;
}
</style>
